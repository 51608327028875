.volatilitygame2 .downloads {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  width: 100vw;
  padding-top: 50px;
}
.volatilitygame2 .downloads .eachdownloads {
  width: 30%;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.volatilitygame2 .downloads .eachdownloads a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  border: 2px solid #143943;
  padding: 10px;
  border-radius: 10px;
  width: 200px;
}
.volatilitygame2 .downloads .eachdownloads a h3 {
  color: #143943;
  text-decoration: none;
  font-size: 1rem;
  height: 40px;
  text-align: center;
}
.volatilitygame2 .downloads .eachdownloads a img {
  width: 150px;
}
.volatilitygame2 .downloads .eachdownloads iframe,
.volatilitygame2 .downloads .eachdownloads video {
  width: 100%;
  height: 240px;
}
.volatilitygame2 .downloads .imagedownload img {
  width: 40%;
  height: 240px;
}

@media (max-width: 768px) {
  .volatilitygame2 .downloads {
    flex-direction: column;
  }
  .volatilitygame2 .downloads .eachdownloads {
    width: 100%;
    margin-bottom: 30px;
  }
}
