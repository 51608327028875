.howtoruninapple {
  padding: 20px;
}
.howtoruninapple img {
  width: 300px;
}

@media (max-width: 768px) {
  .howtoruninapple img {
    width: 100% !important;
  }
}
