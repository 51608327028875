.financialplanningtoolbackend {
  padding: 0px;
}
.financialplanningtoolbackend .tabledata {
  padding: 20px;
}
.financialplanningtoolbackend .eachrecord {
  display: flex;
  justify-content: center;
  align-items: center;
}
.financialplanningtoolbackend .eachrecord h4 {
  padding: 5px;
  width: 17%;
  margin-bottom: 0px;
  font-size: 0.8rem;
}
.financialplanningtoolbackend .eachrecord button {
  margin-bottom: 5px;
  margin-top: 10px;
}

.financialplanningtoolbackend .eachrecord:nth-child(even) {
  background-color: #f0f0f0;
}
