input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.financialplanningtool {
  /* padding: 20px; */
  /* background-color: #fae1b1; */
  width: 100vw;
  overflow-x: hidden;
}
.financialplanningtool > h2 {
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  font-size: 1.2rem;
}

.financialplanningtool h2,
.financialplanningtool h3,
.financialplanningtool h4,
.financialplanningtool h5 {
  color: #121e1e;
  margin: 5px;
}
.financialplanningtool .allsections {
  display: flex;
  /* background-color: #fae1b1; */
  width: auto;
  overflow-x: hidden;
  height: 100vh;
  padding: 0 20px;
}
.financialplanningtool .allsections > div {
  flex-shrink: 0;
  width: 50%;
  margin-top: 20px;
}

.financialplanningtool .sectionnumber {
  font-size: 2rem;
  background-color: #f2b42b;
  padding: 20px 25px;
  border-radius: 30px;
}
.financialplanningtool .sectiontitle {
  margin-bottom: 20px;
  margin-top: 30px;
}
.financialplanningtool > div > div {
  /* margin-top: 10vh; */
}

.financialplanningtool input {
  padding: 10px;
  width: 70%;
  border: 2px solid #121e1e;
  border-radius: 5px;
  margin-bottom: 10px;
}

.financialplanningtool input:focus {
  outline: none;
  border: 2px solid #121e1e;
}
.financialplanningtool #range {
  background-color: white;
  accent-color: #121e1e;
}

.financialplanningtool .section2 {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
}

.financialplanningtool .section3 .nextbtn {
  padding: 20px;
  border: none;
  margin: 20px;
  width: 30%;
  font-weight: bold;
  background-color: #121e1e;
  color: white;
  border-radius: 5px;
  font-size: 1.2rem;
}
.financialplanningtool .section4 .nextbtn {
  padding: 20px;
  border: none;
  margin: 20px;
  width: 60%;
  font-weight: bold;
  background-color: #121e1e;
  color: white;
  border-radius: 5px;
  font-size: 1.2rem;
  margin-top: 60px;
}
.financialplanningtool .section4 {
  width: 100vw !important;
}
.financialplanningtool .section5 {
  width: 100vw !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.financialplanningtool .section5 .nextbutton {
  padding: 20px;
  border: none;
  margin: 20px;
  width: 30%;
  /* margin-left: 30%; */
  font-weight: bold;
  background-color: #121e1e;
  color: white;
  border-radius: 5px;
  font-size: 1.2rem;
  margin-top: 60px;
}
.financialplanningtool .section6 .btnsholder {
  width: 100%;
  display: flex;
  justify-content: center;
}

.financialplanningtool .section6 .btnsholder button {
  padding: 7px 12px;
  border: none;
  margin: 0px;
  margin-left: 0%;
  font-weight: 500;
  background-color: #121e1e;
  color: white;
  border-radius: 20px;
  font-size: 1rem;
  margin-top: 60px;
  margin: 30px 10px;
}

.financialplanningtool .section1 > button {
  padding: 20px;
  border: none;
  margin: 0px;
  width: 80%;
  margin-left: 0%;
  font-weight: bold;
  background-color: #121e1e;
  color: white;
  border-radius: 5px;
  font-size: 1.2rem;
  margin-top: 60px;
}
.financialplanningtool .section6 {
  width: 100vw !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.financialplanningtool .section6 .report,
.financialplanningtool .section6 .mobilereport {
  background-color: white;
  width: 800px;
  margin-top: 20px;
  margin-left: 0px;
  padding: 20px;
  margin-bottom: 50px;
}
.financialplanningtool .section6 .report .row,
.financialplanningtool .section6 .mobilereport .row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.financialplanningtool .section6 .report .introrow,
.financialplanningtool .section6 .mobilereport .introrow {
  background-color: #f2b42b;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}
.financialplanningtool .section6 .report .introrow .each {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.financialplanningtool .section6 .report .row .bubble,
.financialplanningtool .section6 .mobilereport .row .bubble {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  width: 40%;
  margin: 20px;
  border-bottom: 2px solid #f2b42b;
}
.financialplanningtool .section6 .report .row .bubble h4 {
  color: black;
}
.financialplanningtool .section6 .report .row .bubble h5 {
  color: black;
}
.financialplanningtool .section6 .mobilereport .row .bubble h4 {
  color: black;
}
.financialplanningtool .section6 .mobilereport .row .bubble h5 {
  color: black;
}
.financialplanningtool .section3 > div {
  /* width: 50%; */
  /* margin-left: 25%; */
}
.financialplanningtool .section4 > div {
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.financialplanningtool .section3 h5 {
  margin-top: 20px;
  line-height: 20px;
  width: 80%;
}
.financialplanningtool .section3 select {
  padding: 10px;
  width: 80%;
}
.financialplanningtool .section3 select:focus {
  outline: none;
  border: 2px solid #121e1e;
}
.financialplanningtool .section4 h2 {
  text-align: left;
  margin: 20px;
}

.financialplanningtool .section4 .allrisks .eachrisk {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.financialplanningtool .section4 .allrisks .eachrisk h4 {
  margin-right: 20px;
}
.financialplanningtool .section4 .allrisks .eachrisk h6 {
  margin: 5px;
}
.financialplanningtool .section4 .allrisks .eachrisk input {
  margin: 0;
  width: 100px;
  margin-right: 30px;
}

.financialplanningtool .section4 .allrisks .eachrisk input[type="text"] {
  width: 200px;
  margin-right: 30px;
}

.financialplanningtool .section6 .report .row table,
.financialplanningtool .section6 .mobilereport .row table {
  margin-top: 50px;
  width: 100%;
}
.financialplanningtool .section6 .report .row table th {
  background-color: #f2b42b;
}
.financialplanningtool .section6 .report .row table th,
.financialplanningtool .section6 .report .row table td,
.financialplanningtool .section6 .mobilereport .row table th,
.financialplanningtool .section6 .mobilereport .row table td {
  font-size: 0.8rem;
  padding: 5px;
  text-align: center;
  border: 1px solid #f2b42b;
}

.financialplanningtool .section6 .report .row .coachdetails,
.financialplanningtool .section6 .mobilereport .row .coachdetails {
  margin-top: 50px;
  width: 100%;
  background-color: #121e1e;
  padding: 20px;
}
.financialplanningtool .section6 .report .row .coachdetails h4,
.financialplanningtool .section6 .mobilereport .row .coachdetails h4 {
  text-align: center;
  width: 100%;
  color: white;
}
.financialplanningtool .section6 .report .row .coachdetails h5,
.financialplanningtool .section6 .mobilereport .row .coachdetails h5 {
  text-align: center;
  width: 100%;
  color: white;
}
.financialplanningtool .section1 #mobileonly {
  display: none;
}
.mobilereport {
  /* display: none; */
  /* transform: scale(0.3) translateX(-100%) translateY(-100%); */
}

.webcamdiv {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
}

.webcamdiv button {
  background-color: #121e1e;
  border: none;
  padding: 10px;
  color: white;
  margin-top: 10px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .financialplanningtool .allsections > .section1 {
    width: 100vw;
    padding-left: 10px;
  }
  .financialplanningtool .allsections > .section3 {
    width: 100vw;
    padding: 30px;
    padding-left: 10px;
  }
  .financialplanningtool .allsections > .section4 > div {
    align-items: start;
  }

  .financialplanningtool .allsections > .section5 {
    width: 100vw;
    padding: 30px;
    padding-left: 20px;
    align-items: start;
  }

  .financialplanningtool .allsections > .section6 {
    width: 100vw;
    padding: 30px;
    overflow: hidden;
    height: 350vh;
    padding-left: 10px;
    align-items: start;
  }
  .financialplanningtool .section4 > div {
    width: 90%;
  }
  .financialplanningtool .allsections .section4 .allrisks {
    width: 100%;
  }
  .financialplanningtool .section4 .allrisks .eachrisk {
    flex-direction: column;
  }
  .financialplanningtool .section4 .allrisks .eachrisk div {
    width: 100%;
  }
  .financialplanningtool .section4 .nextbtn {
    width: 80%;
    margin-left: 5%;
  }
  .financialplanningtool .section5 .nextbutton {
    width: 80%;
    margin-left: 0%;
  }

  .financialplanningtool .section6 > button {
    width: 85%;
    margin-left: 5px;
  }
  .financialplanningtool .section4 .allrisks .eachrisk input,
  .financialplanningtool .section4 .allrisks .eachrisk input[type="text"] {
    width: 90%;
    padding: 15px;
  }
  .financialplanningtool .section4 .allrisks .eachrisk {
    margin-top: 50px;
    width: 90%;
  }
  .financialplanningtool .section6 .report {
    width: 80vw;
    margin-left: -2vw;
    height: fit-content;
  }
  .financialplanningtool .section6 .report .row {
    height: auto;
  }
  .financialplanningtool .section6 .report .row {
    justify-content: center;
  }
  .financialplanningtool .section6 h2,
  .financialplanningtool .section6 h3,
  .financialplanningtool .section6 h4,
  .financialplanningtool .section6 h5 {
    text-align: center;
  }
  .financialplanningtool .section6 .report .row .bubble {
    width: 80%;
    padding: 30px;
  }
  .section6 .showgraph {
    margin-left: 10px !important;
    width: 100% !important;
  }
  .financialplanningtool .section6 .report .row table th,
  .financialplanningtool .section6 .report .row table td {
    font-size: 0.45rem;
  }
  .financialplanningtool input {
    padding: 15px;
  }
  .financialplanningtool .section3 > div {
    /* margin-left: 20px; */
  }
  .financialplanningtool .section5 .showgraph .bar-container {
    width: 100%;
  }
  .financialplanningtool .section1 #mobileonly {
    display: block;
  }
  .financialplanningtool .allsections {
    flex-direction: column;
  }
  .financialplanningtool .allsections > div {
    min-height: 100vh;
  }
}
