.profilecompletesection {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  z-index: 1020;
}

.profilecompletesection .innerregion {
  width: 50vw;
  height: auto;
  border: 4px solid #eab64a;
  border-radius: 5px;
  padding: 10px;
  background-color: #143943;
  display: flex;
  flex-direction: row;
  width: 80vw;
  overflow-y: scroll;
}
.profilecompletesection .innerregion .left {
  width: 40vw;
}

.profilecompletesection .innerregion .left > div {
  display: flex;
  justify-content: space-between;
}
.profilecompletesection .innerregion .eachfield {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profilecompletesection .innerregion .eachfield h4 {
  color: white;
  margin: 15px;
  margin-right: 20px;
  width: 200px;
}
.profilecompletesection .innerregion h3 {
  color: white;
  margin: 10px;
}
.profilecompletesection .innerregion .eachfield input {
  padding: 7px;
  margin-bottom: 5px;
  border: 2px solid white;
  border-radius: 5px;
  width: 100%;
  font-weight: bold;
}

.profilecompletesection .innerregion button {
  background-color: #eab64a;
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 1rem;
}
.profilecompletesection .innerregion .right {
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  width: 35vw;
}
.profilecompletesection .innerregion .right h4 {
  color: white;
}

.profilecompletesection .innerregion .right img {
  margin-bottom: 30px;
}
.profilecompletesection .innerregion .right textarea {
  width: 100%;
}

@media (max-width: 768px) {
  .profilecompletesection .innerregion {
    flex-direction: column;
  }
  .profilecompletesection .innerregion .left {
    width: 100%;
  }
  .profilecompletesection .innerregion .right {
    width: 100%;
  }
  .profilecompletesection .innerregion .right textarea {
    width: 80%;
  }
  .profilecompletesection .innerregion .eachfield input {
    padding: 15px 7px;
  }
  .profilecompletesection .innerregion .eachfield {
    margin-bottom: 10px;
  }
  .profilecompletesection .innerregion button {
    padding: 20px;
    margin-bottom: 20px;
  }
  .profilecompletesection {
    position: absolute;
    background-color: white;
    min-height: 300vh;
    align-items: flex-start;
    z-index: 1030;
  }
  .profilecompletesection .innerregion {
    min-height: 100%;
    padding-bottom: 50px;
  }
}
