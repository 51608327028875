.userprofile .intro {
  background-color: white;
  padding: 16px;
  height: 240px;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: space-between; */
}
.userprofile .headerrow {
  background-color: #f2b42b;
  padding: 10px;
}
.userprofile .intro .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userprofile .intro .content p {
  border-left: 3px solid #f2b42b;
  padding-left: 10px;
}

.userprofileeditor .intro {
  background-color: #f5f5f5;
  height: auto;
}

.userprofileeditor #profilelink {
  color: black;
  text-decoration: none;
  background-color: #f2b42b;
  padding: 10px;
  font-weight: 600;
  border: 2px solid black;
  font-size: 0.8rem;
  margin: 0 16px;
}

.userprofileeditor .content {
  flex-direction: row;
  flex-wrap: wrap;
}
.userprofileeditor .content > div {
  width: 50%;
}

.userprofileeditor .intro button,
.userprofile .intro button {
  width: 200px;
  padding: 10px;
  margin-top: 10px;
  background-color: #f2b42b;
  font-weight: bold;
}

.userprofileeditor .intro button:hover,
.userprofile .intro button:hover {
  cursor: pointer;
}

.userprofileeditor .content > div input,
.userprofileeditor .content > div textarea {
  width: 90%;
  padding: 7px;
  outline: none;
  border: 2px solid white;
}
.userprofileeditor .content > div input:focus,
.userprofileeditor .content > div textarea:focus {
  outline: none;
  border-bottom: 2px solid #f2b42b;
}
.userprofileeditor .content > div textarea {
  height: 200px;
}

.userprofile .intro img {
  width: 160px;
  aspect-ratio: 1;
  border-radius: 5px;
}

.userprofile .detailswrapper {
  display: flex;
  justify-content: space-between;
}

.userprofile .details {
  padding: 16px;
}

.userprofile .firstdetails {
  flex: 0.4;
}
.userprofile .seconddetails {
  flex: 1;
}

.userprofile .details p {
  /* border: 1px solid black; */
  border-radius: 3px;
  padding: 10px;
  font-weight: 400;
  border-left: 3px solid #f2b42b;
}

.userprofile .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f2b42b;
  border: 2px solid #f2b42b;
  padding: 20px;
  border-radius: 5px;
  max-height: 80vh;
}

.userprofile .modal > div {
  max-height: 65vh;
  overflow-y: scroll;
}

.userprofile .modal input {
  width: 300px;
  padding: 7px;
}

.userprofile .modal button {
  margin-top: 20px;
  background-color: black;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-right: 10px;
}

.userprofileeditor .modal table {
  width: 90vw;
  height: 60vh;
  overflow-y: scroll;
}

.userprofileeditor table th,
.userprofileeditor table tr {
  display: flex;
}

.userprofileeditor table th td,
.userprofileeditor table tr td {
  flex: 1;
  text-align: left;
  margin-bottom: 10px;
}
