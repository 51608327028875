.members .memberslist {
  padding: 20px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.members .memberslist table {
  width: 95vw;
}
.members .memberslist .inputs {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.members .memberslist table thead th {
  background-color: #f2b42b;
  padding: 10px;
  text-align: left;
}

.members .memberslist table td a {
  color: black;
  text-decoration: none;
  background-color: #f2b42b;
  padding: 3px 10px;
}

.members .memberslist input,
.members .memberslist select,
.members .memberslist button {
  width: 30vw;
  border-radius: 0;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 3px;
}

.members .memberslist button {
  width: 20vw;
  background-color: #f2b42b;
}

.members .memberslist table tbody td {
  padding: 5px;
  border-right: 1px solid #f2b42b;
  border-bottom: 1px solid #f2b42b;
}

@media (max-width: 768px) {
}
