@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./Assets/Montserrat/Montserrat-VariableFont_wght.ttf) format("truetype");
  font-style: normal;
}

.App {
  font-family: Montserrat !important;
}

.loadingcontainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  align-content: center;
  justify-content: center;
  padding-top: 30vh;
  z-index: 1020;
}
