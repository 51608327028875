.showgraph {
  margin-left: 0px;
}
.bar-list {
  border-left: 3px solid black;
  border-bottom: 3px solid black;
}
.bar-list span:nth-child(even) {
  background-color: #f2b42b;
}

.bar-list span:nth-child(odd) {
  background-color: #2b4a4e;
}

.y-axis {
  font-weight: bold;
  transform: rotate(-90deg);
  height: 30px;
  width: fit-content;
  margin-right: -50px;
}

.bar-container span {
  font-weight: bold;
}

.section6 .showgraph {
  margin-left: 30px;
}
.section6 .y-axis {
  margin-left: 0px;
  transform: rotate(0deg);
}
