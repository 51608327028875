.others .eachdashboarditem {
  width: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin: 5px;
}

.others .downloads {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
}

.others .eachdashboarditem .content {
  width: 210px;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #143943; */
  border: 2px solid #f0f0f0;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
.others .eachdashboarditem .content:hover {
  cursor: pointer;
  border: 2px solid #eab64a;
  background-color: #eab64a;
}
.others .eachdashboarditem .content .allcontent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.others .eachdashboarditem .content h4 {
  text-align: center;
  font-size: 0.9rem;
}
.others .eachdashboarditem .content img {
  height: 40px;
  width: auto;
  margin-right: 20px;
}

.others .eachdashboarditem .content .newtext {
  position: absolute;
  right: 0;
  top: -20px;
  background-color: red;
  border-radius: 5px;
}
.others .eachdashboarditem .content .newtext h3 {
  margin: 0;
  padding: 0;
  color: white;
  padding: 5px 10px;
}

@media (max-width: 768px) {
  .others .newrow {
    justify-content: center;
    align-items: center;
  }
  .others .eachdashboarditem {
    width: 75%;
    overflow: hidden;
  }
  .others {
    padding: 0px;
  }
  .others .eachdashboarditem .content h4 {
    font-size: 0.7rem;
  }
  .others .eachdashboarditem .content img {
    margin-right: 5px;
  }
  .others .eachdashboarditem .content {
    width: 80%;
  }
}
