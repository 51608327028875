.excelcalculators .classroomcontent h2 {
  margin-left: 50px;
  color: #143943;
  width: 100%;
  margin-bottom: 5px;
}
.excelcalculators .classroomcontent h4 {
  margin-left: 50px;
  color: #143943;
  width: 100%;
  margin-top: 10px;
}
.excelcalculators .classroomcontent {
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.excelcalculators .classroomcontent .eachvideo {
  width: 400px;
  margin: 1.5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.excelcalculators .classroomcontent .eachvideo iframe {
  width: 100%;
  height: 240px;
  /* border-radius: 10px; */
}

.excelcalculators .rvcccontent {
  margin: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.excelcalculators .classroomcontent .rvcccontent .eachvideo a {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: black;
  font-weight: bold;
  gap: 1rem;
  border: 2px solid #f5f5f5;
  padding: 16px;
}

.excelcalculators .classroomcontent .rvcccontent .eachvideo a img {
  width: 60px;
}

@media (max-width: 768px) {
  .excelcalculators .rvcccontent {
    width: 100%;
  }
  .excelcalculators .classroomcontent .eachvideo {
    width: 100% !important;
    margin: 0px !important;
  }
}
