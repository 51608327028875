.header {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #143943;
  background-color: white;
  align-items: center;
}
.header .profile {
  /* background-color: rgba(242, 180, 43, 0.4); */
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header .profile .layer {
  margin-right: 10px;
}
.header .profile h3 {
  font-size: 1rem;
  letter-spacing: 1px;
  margin-bottom: 5px !important;
}
.header a {
  color: black;
  text-decoration: none;
  font-weight: "700";
  background: none;
}
.header #registerLink {
  margin: 5px 10px 0 10px;
}
.header .profile button {
  border: none;
  background-color: #f2b42b;
  padding: 10px 10px;
  color: black;
  font-weight: bolder;
  border-radius: 0px;
  margin-right: 5px;
}
.header #profile {
  width: 40px;
  height: 40px;
  margin-left: 15px;
  border-radius: 30px;
  border: 3px solid rgba(242, 180, 43, 1);
  margin-right: 0;
}
.header .layer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 0px;
  padding-left: 0;
}
/* Navbar container */
.navbar {
  overflow: hidden;
  top: 10px;
  right: 10px;
  /* display: none; */
}

/* Links inside the navbar */
/* .navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
} */

/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown span:hover {
  background-color: #f2b42b;
  cursor: pointer;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: red;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  /* min-width: 160px; */
  width: 200px;
  margin-left: -150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content span {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  display: block;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.intro {
  background-color: #f2b42b;
  padding: 3px 10px;
}
.intro h5 {
  margin: 5px;
}

.header .profile .logoutbtn {
  background-color: black;
  color: white;
}

@media (max-width: 768px) {
  .navbar {
    display: block;
  }
  .header {
    flex-direction: row;
    padding: 0;
  }
  .header img {
    width: 50%;
    margin-bottom: 10px;
  }
  .header .profile {
    margin-bottom: 0px;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    align-items: flex-end;
  }
  .header .profile .layer div h4 {
    margin-bottom: 10px !important;
    text-align: right;
  }
  .header #profile {
    margin: 0;
    margin-bottom: 5px;
    width: 60px;
    height: 60px;
  }
  .header .profile button {
    margin-bottom: 10px;
    width: 100%;
  }
  .header .profile button img {
    width: 100%;
    margin: 0px;
    object-fit: cover;
  }

  .header .profile .layer {
    justify-content: center;
    display: flex;
    flex-direction: column-reverse;
    margin-right: 0px;
  }

  .header .profile .layer h3,
  .header .profile .layer h4 {
    display: none;
  }
  .header .profile .layer > div {
    /* display: none; */
    /* justify-content: space-between; */
  }
  .header .profile .layer > button {
    display: none;
  }
  .header .profile .onlydesktop {
    display: none !important;
  }
}
